<template>
  <div id="FamilyIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.stuInfo" show-action placeholder="请输入关键词查询" @search="toQuery">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.xn +'年' +codeConvertLabel(row.xq,dict.xj_xqgl)}}</span></span>
          </template>
          <template #label>
            <div class="custom-centent margin6 hidden-text95">
              <span @click="toStuDeatil(row)"> <b style="font-size:15px;color:black">{{row.stuName}}</b> {{  row.stuNo}}</span>
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="date" size="15" /> 时间: {{parseTime(row.createTime, "{y}-{m}-{d}")}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="user-o" /> 说明: {{ row.explain }}
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.approvalStatus === 0 " class="audit info-color b-color-info">待提交</span>
            <span v-else-if="statustag(row.approvalStatus,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <span class="table-detail-btn detail-pos" @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span v-if="statustag(row.approvalStatus,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" :disabled="stuDisableBtn(row.approvalStatus,'del')" @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" :disabled="stuDisableBtn(row.approvalStatus,'edit')" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
            <!-- v-if="row.approvalStatus !== 9" -->
            <!-- <van-button class="table-edit-btn" v-else @click="toCheckScore(row)" :disabled="row.scoreCheckInfos.length  !== 0" plain round size="small" type="primary">打分</van-button> -->
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <!-- 提交 -->
    <div class="submit-button shadow-style">
      <van-button round block type="primary" @click="doAdd">新增特殊加减分项</van-button>
    </div>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import crudEvaluationAddScore from '@/api/modules/evaluation/evaluationAddScore'

export default {
  name: "EvaluationActivity",
  mixins: [initData],
  dicts: ['evaluation_activity_type', 'xj_nj', 'xj_xqgl', 'xj_shzt'],
  data() {
    return {
      showYearPicker: false,
      defaultIndex: 1,
      searchTetx: "",
      options: [],
    };
  },
  created() { },
  watch: {
    "dict.xj_nj": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0) {
          this.options = this.convertData(newval);
        }
      },
    },
  },
  methods: {
    isDateTimeInRange(startDateTime, endDateTime) {
      var now = new Date();
      var startTime = new Date(startDateTime);
      var endTime = new Date(endDateTime);

      if (now < startTime) {
        return "未开始";
      } else if (now > endTime) {
        return "已结束";
      } else {
        return "进行中";
      }
    },
    beforeInit() {
      this.url = "/api/evaluation/addScore";
      this.sort = ['approvalStatus,asc', 'createTime,desc', 'facultyNo,asc', 'specNo,asc', 'classNo,asc', 'stuName,asc'];
      this.query.type = 2
      return true;
    },
    doAdd() {
      this.$router.push(
        { path: "/js/special/addSubtract/form" }
      );
    },
    toDetail(data) {
      this.$router.push({
        path: "/js/special/addSubtract/detail",
        query: { id: data.id, status: data.approvalStatus },
      });
    },
    toStuDeatil(data) {
      this.$router.push({
        path: "/js/stu/detail",
        query: { stu: btoa(data.stuNo) },
      });
    },
    // 编辑
    toEdit(data) {
      this.$router.push({
        path: "/js/special/addSubtract/form",
        query: { id: data.id },
      });
    },
    //打分
    toCheckScore(data) {
      this.$router.push({
        path: "/xs/special/attendance/check/form",
        query: { id: data.id },
      });
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          crudEvaluationAddScore.del([data.id])
            .then((res) => {
              this.$notify({
                type: "success",
                message: "操作成功",
                duration: 2000,
              });
              this.init();
              this.$set(data, "delLoading", false);
            })
            .catch((e) => {
              this.$set(data, "delLoading", false);
            });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    onConfirm(value) {
      this.showYearPicker = false;
      this.query.year = value.label;
      this.toQuery();
    },
    convertData(dict) {
      let options = [];
      dict.forEach((item) => {
        let data = { text: item.label + "年", value: item.value };
        options.push(data);
      });
      if (options) {
        this.query.year = new Date().getFullYear().toString();
      }
      return options;
    },
    changeYear(val) {
      this.$nextTick((e) => {
        this.query.year = val;
      });
      this.toQuery();
    },
    cleanSearch() {
      this.searchTetx = "";
      this.query = {};
      this.toQuery();
    },
    openShowHandler() {
      // 默认选中数据
      this.dict.xj_nj.forEach((item, index) => {
        if (this.query.year) {
          if (this.query.year === item.value) {
            this.defaultIndex = index;
          }
        } else if (new Date().getFullYear().toString() === item.value) {
          this.defaultIndex = index;
        }
      });
      this.showYearPicker = true;
    },
    stuDisableBtn(spzt, type) {
      let status = parseInt(spzt);
      if ([9].indexOf(status) > -1) {
        return true;
      }
      if ([101, 201, 301, 3, 7, 701,].indexOf(status) > -1) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang='scss' scoped>
.van-list {
  margin-bottom: 54px;
}
.van-cell__title {
  font-size: 16px;
  text-align: left;
  min-width: 70%;
}
::v-deep .van-dropdown-menu__bar {
  height: 44px;
  box-shadow: 0 0px 0px rgb(100 101 102 / 12%);
}
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
#opt-style {
  .van-cell__title {
    min-width: 50%;
    font-size: 14px;
    line-height: 32px;
  }
}
</style>
